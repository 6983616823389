// import { getChainSettings } from "../../utils/chain";
import brand from "./brand";

// const chainSetting = getChainSettings();

const light = {
  fontPrimary: `rgb(${brand.common.black})`,
  fontPrimaryInverse: `rgb(${brand.common.white})`,
  fontSecondary: "rgba(27, 32, 44, 0.6)",
  fontTertiary: "rgba(27, 32, 44, 0.6)",
  fontQuaternary: "rgba(27, 32, 44, 0.1)",
  fontPositive: "rgba(82, 204, 138, 1)",
  fontPending: "rgba(255, 187, 55, 1)",
  fontNegative: "rgba(238, 68, 68, 1)",
  strokeBase: "#F6F6F7",
  strokeBox: "#e1e2e4",
  strokeBoxSelected: "#CBCCCE",
  fillPanel: "#fff",
  fillPopup: "#fff",
  fillPopupHover: "#F8F8F9",
  fillSub: "#EFEFF0",
  fillSubDark: "#262B37",
  fillBase: "#F2F2F2",
  fillButton: `rgb(${brand.common.green})`,
  fillTooltip: `rgb(${brand.common.green})`,
  fillGradientIcon:
    "linear-gradient(135deg, rgba(27, 32, 44, 0.05) 0%, rgba(27, 32, 44, 0) 100%)",
  fillPanelBlanket: "rgba(255, 255, 255, 0.8)",
  shadowPanel:
    "0px 0.5px 1px 0px rgba(27, 32, 44, 0.02), 0px 2px 4px 0px rgba(27, 32, 44, 0.03), 0px 6px 16px 0px rgba(27, 32, 44, 0.05);",
  fillAlpha: "rgba(27, 32, 44, 0.05)",
  fillBeta: "rgba(27, 32, 44, 0.02)",
  fillGamma: "rgba(27, 32, 44, 0)",
  fillActiveBlue: "blue",
  fillPositive: `rgb(${brand.common.green})`,
  fillNegative: "red",
  fillPending: "yellow",
  theme700: `rgb(${brand.common.darkGreen})`,
  theme500: `rgb(${brand.common.green})`,
  theme100: `rgba(${brand.common.green}, 0.1)`,
  linkBlue: `rgb(${brand.common.blue})`,
  overviewPanel: `linear-gradient(to right, rgb(${brand.common.green}), rgb(${brand.common.blue}))`,
  searchInputText: `rgb(${brand.common.midGrey})`,
  searchInputBorder: `rgb(${brand.common.darkGrey})`,
  filterDivider: `rgb(${brand.common.white}, 0.29)`,
  white: `rgb(${brand.common.white})`,
  black: `rgb(${brand.common.black})`,
  placeHolder: `rgb(${brand.common.white}, 0.5)`,
  eventName: `rgb(${brand.common.white}, 0.31)`,
};

export default light;
