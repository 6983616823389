const brand = {
   common: {
      white: "255,255,255",
      green: "38,219,141",
      black: "5,5,6",
      blue: "14,157,229",
      darkGrey: "69,69,72",
      midGrey: "122,122,123",
      lightGrey: "210,210,210",
      lightestGrey: "242,242,242",
      darkGreen: "20,129,83"
   },
   light: {
      stroke: "243,243,243",
   },
   dark: {
      base: "36,36,38",
      panel: "7,7,9",
      stroke: "30,30,30",
      grey: "122,122,123"
   }
}

export default brand;
